@font-face {
  font-family: 'Uthmani';
  src: url('/fonts/uthmani/UthmanicHafs1-Ver09.woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Indopak';
  src: url('/fonts/indopak/pdms-saleem.woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Scheherazade';
  src: url('/fonts/scheherazade/scheherazade.woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Traditional Arabic';
  src: url('/fonts/traditional-arabic/trado.woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nashk';
  src: url('/fonts/droid-nashk/DroidNaskh-Regular.woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kufi';
  src: url('/fonts/droid-kufi/DroidKufi-Regular.woff'); 
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hafs';
  src: url('/fonts/uthmani-hafs/fontquran-2019.woff'); 
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

input:focus {
  outline:none;
}
::placeholder {
  color: #aaa;
}